import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import PageHeader from '../components/pageHeader';
import GetHelpSection from '../components/getHelpSection';
import SEO from '../components/seo';
import { ContentContainer, Sidebar, Main } from '../components/container';
import searchIcon from '../images/search.svg';
import emptySearchResultsImg from '../images/house.svg';
import _ from 'lodash';

import './__styles__/certificates.module.css';

const GROUPS = [
  '123456789',
  'abc',
  'def',
  'ghi',
  'jkl',
  'mno',
  'pqr',
  'stu',
  'vwxyz',
];

const groupHeader = (group) =>
  `${group[0].toUpperCase()}-${group.slice(-1).toUpperCase()}`;

const groupProperties = (properties) => {
  const grouped = GROUPS.reduce((res, group) => {
    res[group] = [];
    return res;
  }, {});

  const regex = /^([0-9a-z&,-]+)\s(?:(E|S|N|W|NW|NE|SE|SW)\s)?(.+)$/i;

  for (const property of properties) {
    const matches = property.streetAddress.match(regex);

    if (!matches) continue;

    // eslint-disable-next-line
    let [_, streetNumber, cardinalDirection, streetName] = matches;

    streetName = streetName.trim();
    streetNumber = parseInt(streetNumber.trim());
    cardinalDirection = cardinalDirection ? cardinalDirection.trim() : null;
    const group = GROUPS.find((g) => g.includes(streetName[0].toLowerCase()));

    if (!grouped[group]) continue;

    grouped[group].push(
      Object.assign({}, property, {
        streetName,
        streetNumber,
        cardinalDirection,
      })
    );
  }

  for (const [group, records] of Object.entries(grouped)) {
    grouped[group] = records.sort(sortProperties);
    if(_.isEmpty(grouped[group])) {
      delete grouped[group]
    }
  }

  return grouped;
};

const sortProperties = (a, b) => {
  const aStreet =
    a.streetName.search(/^[0-9]{1}/) > -1
      ? parseInt(a.streetName)
      : a.streetName;
  const bStreet =
    b.streetName.search(/^[0-9]{1}/) > -1
      ? parseInt(b.streetName)
      : b.streetName;

  const isTheSameStreet =
    aStreet === bStreet ||
    a.streetName.startsWith(b.streetName) ||
    b.streetName.startsWith(a.streetName);

  if (isTheSameStreet) {
    if (a.streetNumber !== b.streetNumber) {
      return a.streetNumber > b.streetNumber ? 1 : -1;
    }
    if (a.cardinalDirection !== b.cardinalDirection) {
      return a.cardinalDirection > b.cardinalDirection ? 1 : -1;
    }
  }

  return aStreet > bStreet ? 1 : -1;
};

const CertificateLink = ({ streetAddress, slug }) => (
  <li styleName="list-item">
    <a href={`/${slug}`}>{streetAddress}</a>
  </li>
);

const ElevationCertificateSearch = ({ onSearch }) => {
  const debouncedOnSearch = React.useRef(_.debounce(onSearch, 150))

  return (
    <div styleName="search-box">
      <img src={searchIcon} alt="Search ECs" />
      <input
        aria-label="ec-search"
        onChange={event => debouncedOnSearch.current(_.trim(event.target.value))}
        placeholder="Search for address"
      />
    </div>
  );
}

const ElevationCertificates = ({ data }) => {
  const [properties, setProperties] = React.useState(data.forerunner.properties);
  const groupedProperties = groupProperties(properties);

  const search = term => {
    if(_.isEmpty(term)) {
      setProperties(data.forerunner.properties)
    } else {
      const lowerCasedTerm = term.toLowerCase()
      setProperties(properties.filter(property => property.streetAddress.toLowerCase().includes(lowerCasedTerm)))
    }
  }

  return (
    <Layout>
      <SEO
        title="Elevation Certificates"
        description={`List of ${data.site.siteMetadata.localityNameShort}'s Elevation Certificates.`}
      />
      <PageHeader title={data.markdownRemark.frontmatter.title} />
      <ContentContainer>
        <Sidebar>
          <header>Street Address</header>
          <ElevationCertificateSearch onSearch={search} />
          <nav role="navigation" aria-label="content navigation">
            <ul>
              {Object.keys(groupedProperties).map((group) => (
                <li key={`link-${group}`}>
                  <a
                    href={`#${group}`}
                    aria-label={`${group[0]} through ${
                      group[group.length - 1]
                    }`}
                  >
                    {groupHeader(group)}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </Sidebar>
        <Main>
          {!!data.markdownRemark.html && (
            <div
              styleName="content"
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />
          )}

          {_.isEmpty(groupedProperties) && (
            <div styleName="empty-search-results">
              <img src={emptySearchResultsImg} alt="No search results" />
              <div styleName="empty-search-results-title">
                There are no results for your search
              </div>
              <div styleName="empty-search-results-subtitle">
                It may be helpful to search only by your street name.
              </div>
            </div>
          )}

          {Object.keys(groupedProperties).map((group) => {
            return (
              <section key={group} styleName="section">
                <h2 id={group}>{groupHeader(group)}</h2>
                <ul styleName="list">
                  {groupedProperties[group].map((data) => (
                    <CertificateLink {...data} key={data.id} />
                  ))}
                </ul>
              </section>
            );
          })}
        </Main>
      </ContentContainer>
      <GetHelpSection title="Looking for help with your EC?">
        <p>
          Elevation Certificates can be complicated. If you have questions about
          your existing EC or about obtaining a new EC, we can provide support.
        </p>
      </GetHelpSection>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    forerunner {
      properties(withCertificates: true) {
        id
        slug
        streetAddress
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
    site {
      siteMetadata {
        localityNameShort
      }
    }
  }
`;

export default ElevationCertificates;
